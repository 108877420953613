import { useApiQuery } from '@hooks/api/useApiQuery';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@components/card';
import CreateSkinModal from '../components/modals/create_skin';
import { apiGetVisualElements } from '@api/visual_elements';
import CreateVisualElementModal from '../components/modals/create_visual_element';

const VisualElements = () => {
    const { data: skins, handleRequest: refetchElements } = useApiQuery({
        method: apiGetVisualElements,
        isInitialRequest: true
    });

    return (
        <div className="flex flex-col gap-8">
            <h2 className="font-bold text-2xl">Visuals Management</h2>

            <CreateVisualElementModal onSuccess={() => refetchElements()} />

            {skins && (
                <div className="flex gap-4 flex-wrap">
                    {skins.map((item) => (
                        <Card
                            key={item._id}
                            className="w-[calc(50%-8px)] md:w-[calc(23%-20px)] md:min-w-[200px]  flex flex-col justify-between"
                        >
                            <CardHeader className="flex justify-center w-full">
                                <CardTitle className="flex justify-center items-center w-full gap-5">
                                    <span>{item?.title?.uk}</span>

                                    <span className="text-sm">{item?.type}</span>
                                </CardTitle>
                            </CardHeader>
                            <CardContent className="flex justify-center items-center">
                                {item.file && (
                                    <img
                                        src={`${process.env.REACT_APP_IMAGE_URL}${item.file}`}
                                        alt={item?.title?.uk}
                                        className="w-25 h-50 object-cover"
                                    />
                                )}
                            </CardContent>
                            <CardFooter className="flex gap-2">
                                {/* <UpdateTaskModal data={item} onSuccess={refetchTasks} />
                                <DeleteCardModal
                                    title="Delete current task"
                                    content="Are you sure you want to delete that task?"
                                    onSuccess={() => {
                                        handleDeleteTask(item.id);
                                        refetchTasks();
                                    }}
                                /> */}
                            </CardFooter>
                        </Card>
                    ))}
                </div>
            )}
        </div>
    );
};

export default VisualElements;
