import { CardStackIcon, DashboardIcon, FileMinusIcon } from '@radix-ui/react-icons';
import { ERoutes } from '../typescript/enums';
import { RainbowIcon } from 'lucide-react';

export const routes = [
    {
        label: 'Dashboard',
        Icon: DashboardIcon,
        link: ERoutes.HOME
    },
    {
        label: 'Cards',
        Icon: CardStackIcon,
        link: ERoutes.CARDS
    },
    {
        label: 'Daily Bonus',
        Icon: CardStackIcon,
        link: ERoutes.DAILY
    },
    {
        label: 'Daily Tasks',
        Icon: CardStackIcon,
        link: ERoutes.TASKS
    },
    {
        label: 'Daily Chests',
        Icon: CardStackIcon,
        link: ERoutes.CHESTS
    },
    {
        label: 'Combo',
        Icon: CardStackIcon,
        link: ERoutes.COMBO
    },
    {
        label: 'Skins',
        Icon: FileMinusIcon,
        link: ERoutes.SKINS
    },
    {
        label: 'Visuals',
        Icon: RainbowIcon,
        link: ERoutes.VISUAL_ELEMENTS
    }
];
