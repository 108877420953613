import axios, { AxiosResponse } from 'axios';
import { IImage } from 'src/typescript/requests';
import { TLocalization } from '@types';

export enum VisualElementEnum {
    BG = 'background',
    SHINE = 'shine'
}

export type TCreateVisualElement = {
    title: TLocalization;
    file: IImage;
    type: VisualElementEnum;
    hidden: boolean;
};

export type TVisualElement = {
    _id: string;
    title: TLocalization;
    file: string;
    type: VisualElementEnum;
    hidden: boolean;
};

export type TUpdate = TCreateVisualElement & { id: string };

// Fetch all visual_elements
export const apiGetVisualElements = (): Promise<AxiosResponse<TVisualElement[]>> => {
    return axios.get('/visual_elements');
};

// Fetch a VisualElement by ID
export const apiGetVisualElementById = (id: string): Promise<AxiosResponse<TVisualElement>> => {
    return axios.get(`/visual_elements/id/${id}`);
};

// Create a new VisualElement
export const apiCreateVisualElement = (
    data: TCreateVisualElement
): Promise<AxiosResponse<TVisualElement>> => {
    return axios.post('/visual_elements', data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
};

// Update an existing VisualElement
export const apiUpdateVisualElement = ({
    id,
    ...data
}: TUpdate): Promise<AxiosResponse<TVisualElement>> => {
    return axios.put(`/visual_elements/${id}`, data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
};

// Delete a VisualElement
export const apiDeleteVisualElement = (id: string): Promise<AxiosResponse<boolean>> => {
    return axios.delete(`/visual_elements/${id}`);
};
