import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import { ERoutes } from './typescript/enums';
import Home from '@pages/home';
import Login from '@pages/login';
import Layout from './ui/layout';
import Cards from '@pages/cards';
import { UserContextProvider } from '@hooks/context/user_context';
import Daily from '@pages/daily';
import Tasks from '@pages/tasks';
import Chests from '@pages/chests';
import Combo from '@pages/combo';
import Skins from '@pages/skins';
import VisualElements from '@pages/visual_elemets';

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<UserContextProvider />}>
            <Route element={<Layout />}>
                <Route path={ERoutes.HOME} element={<Home />} />
                <Route path={ERoutes.CARDS} element={<Cards />} />
                <Route path={ERoutes.DAILY} element={<Daily />} />
                <Route path={ERoutes.TASKS} element={<Tasks />} />
                <Route path={ERoutes.CHESTS} element={<Chests />} />
                <Route path={ERoutes.COMBO} element={<Combo />} />
                <Route path={ERoutes.SKINS} element={<Skins />} />
                <Route path={ERoutes.VISUAL_ELEMENTS} element={<VisualElements />} />
            </Route>
            <Route path={ERoutes.LOGIN} element={<Login />} />
        </Route>
    )
);
