export enum ERoutes {
    HOME = '/',
    LOGIN = '/login',
    CARDS = '/cards',
    DAILY = '/daily',
    TASKS = '/tasks',
    CHESTS = '/chests',
    COMBO = '/combo',
    SKINS = '/skins',
    VISUAL_ELEMENTS = '/visuals'
}

export enum ECardType {
    MARKET = 'market',
    TEAM = 'team',
    LEGAL = 'legal',
    SPECIAL = 'specials'
}

export enum ETaskType {
    LEVEL = 'level',
    REFERRALS = 'referrals',
    NEW_REFERRALS = 'new_referrals',
    CARD = 'card'
}

export enum ECreateTabsType {
    TRANSLATIONS = 'translations',
    SETTINGS = 'settings',
    LEVELS = 'levels'
}

export enum EDailyType {
    TRUST = 'TRUST',
    TWITTER = 'TWITTER',
    EXTERNAL = 'EXTERNAL',
    EXTERNAL_LINK = 'EXTERNAL_LINK',
    TELEGRAM = 'TELEGRAM'
}

export enum EDailyChestType {
    DAILY = 1,
    DAILY_TASK = 2,
    TASK = 3
}
